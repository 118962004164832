<app-header></app-header>
<section class="book-online">
    <div class="container">
        <picture>
            <source media="(max-width: 768px)" srcset="./../../../assets/Black_Silk.JPG">
            <source media="(min-width: 768px)" srcset="./../../../assets/Black_Silk_V.JPG">
            <img src="./../../../assets/Black_Silk.JPG" alt="Snow" class="book-online-image">
        </picture>
        <div class="book-online-content">
            <div class="book-online-content-1">
                <p class="book-online-content-1-header">BOOK ONLINE</p>
                <p class="book-online-content-1-text">
                    We've partnered with <span class="bold">Fresha</span>, the most advanced salon software <br>
                    in beauty & wellness industry. <span class="bold">Simply scan or click the QR code <br>
                    below to make your appointment with us</span>
                </p>
            </div>
            <div class="book-online-content-2">
                <a href="https://www.fresha.com/book-now/april-lashes-brow-paddington-r45htfy9/all-offer?pId=793346" target="_blank"><img src="./../../../assets/Book-Online/BookQR.png" alt="Snow" style="width:100%;"></a>
            </div>
        </div>
    </div>
</section>
<section class="book-online-footer-container">
    <app-footer></app-footer>
</section>