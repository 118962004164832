<app-header></app-header>
<section class="gallery">
    <div class="gallery-hero">
        <img class="gallery-hero-bg" src="./../../../assets/Black_Silk.JPG" alt="Snow" style="width:100%;">
        <div class="gallery-hero-text">
            <p>GALLERY</p>
            <p>APRIL LASHES & BROW</p>
        </div>
    </div>

    <div class="gallery-main">
        <!-- <div class="gallery-grid-1"> <img src="./../../../assets/Gallery/April Lashes-Photo.jpg" alt="Gallery Images"></div> -->
        <div class="gallery-grid-2"> <img src="./../../../assets/Gallery/GALLERY-1.WEBP" alt="Gallery Images"></div>
        <div class="gallery-grid-3"> <img src="./../../../assets/Gallery/GALLERY-4.WEBP" alt="Gallery Images"></div>
        <div class="gallery-grid-4"> <img src="./../../../assets/Gallery/GALLERY-3.WEBP" alt="Gallery Images"></div>
        <div class="gallery-grid-5"> <img src="./../../../assets/Gallery/GALLERY-2.WEBP" alt="Gallery Images"></div>
    </div>

    <app-footer></app-footer>
</section>