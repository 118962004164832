<app-header></app-header>

<section class="home">
    <section class="home-1">
        <div class="image-container">
            <img src="./../../../assets/Black_Silk.JPG" class="home-lashes" alt="Home Lashes">
        </div>
        <div class="text-container">
            <h1 class="home-1-header">
                APRIL LASHES & BROW <br>
                PADDINGTON <br>
            </h1>
            <p class="home-1-text">
                Caring for lashes & brows, <br>
                as if they're <span class="line"></span><span class="our-own-text">our own</span>
            </p>
        </div>
    </section>

    <section class="home-2-meet-the-founders">
        <div class="home-2-header-container">
            <div class="home-founder-1">
                <div class="home-founder-content-1">
                    <p>MEET<br> <span>the</span></p>
                </div>
                <div class="home-founder-content-2">
                    <img src="./../../../assets/Home/Home-MeetTheFounders.jpg" alt="Founders" class="founders">
                </div>
                <div class="home-founder-content-3">
                    <p>FOUNDERS</p>
                </div>
            </div>
            
            <div class="home-founder-2">
                <p>
                    Our journey began worlds apart, but destiny had other plans. Two worlds, two cultures, ONE dream.<br><br>
                    We've embraced our diverse backgrounds and channelled them into a salon that celebrates individuality, self-expression,<br>
                    and the universal desire for beauty and connection. April Lashes & Brow is more than just a place to get a lash & brow makeover;<br>
                    it's a sanctuary where stories are shared,friendships are forged, and dreams are nurtured <br><br>
                    It's these moments that remind us of the profound impact we can have on the lives of others,<br>
                    and they serve as our driving force behind everything we do at April Lashes & Brow.
                </p>
            </div>

            <div class="home-founder-3">
                <h1>Silvany & Lina</h1>
                <h2>Founders of April Lashes & Brow Paddington</h2>
            </div>
        </div>
    </section>

    <section class="home-3">
        <div class="home-3-container">
            <div class="home-schedule-hdr">
                <div class="home-schedule-hdr-text">
                    <p>OUR DOORS<br>ARE OPEN</p>
                    <h1>7 <span>Days<br>a</span> WEEK</h1>
                </div>
                <div class="home-schedule-hdr-img"></div>
            </div>
            <div class="home-schedule-dtl"></div>
        </div>
        <div class="home-schedule-list">
            <div class="home-schedule-list-text1">
                <span class="bold">Mon</span> : 9 Am - 7 Pm <br>
                <span class="bold">Tue</span> : 9 Am - 7 Pm <br>
                <span class="bold">Wed</span> : 8 Am - 8 Pm <br>
                <span class="bold">Thu</span> : 9 Am - 8 Pm
            </div>
            <div class="home-schedule-list-text2">
                <span class="bold">Fri</span> : 8 Am - 7 Pm <br>
                <span class="bold">Sat</span> : 9 Am - 5 Pm <br>
                <span class="bold">Sun</span> : by appointment
            </div>
            <div class="home-schedule-list-text3">
                Notes: <br>
                call 0473 319 917 <br>
                24hrs in advance to book sunday
            </div>
        </div>
    </section>

    <section class="home-4">
        <div class="home-4-container">
            <div class="home-4-content-1">
                <p class="home-4-content-1-header">APRIL LASHES & BROW.</p>
                <h2>where beauty meets precision</h2>

                <p class="home-4-content-1-discover">
                    DISCOVER THE PERFECT EYELASH AND BROW <br>
                    TO ENHANCE YOUR NATURAL BEAUTY <br>
                </p>

                <div class="home-4-content-1-text-1">
                    <p>+61 473 319 917 <br>
                    april.lashesandbrow{{'@'}}gmail.com <br>
                    {{'@'}}april.lashesandbrow <br>
                    </p>
                </div>
                <div class="home-4-content-1-text-2">
                    Level 2, 310 Oxford Street, <br>
                    Paddington, <br>
                    New South Wales <br>
                    2021 <br>
                </div>
            </div>
            <div class="home-4-content-2">
                <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.160963070913!2d151.2259919764407!3d-33.885508119925795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ae066ddf450d%3A0x1de9da6408fca07!2s310%20Oxford%20St%2C%20Paddington%20NSW%202021%2C%20Australia!5e0!3m2!1sen!2sid!4v1729645730567!5m2!1sen!2sid" 
                    style="border:0;" 
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>
            </div>
        </div>
        <app-footer></app-footer>
    </section>
</section>